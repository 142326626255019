<script>
const state = () => {
  return {
    menu: [
      {
        icon: "MenuIcon",
        pageName: "users",
        title: "Manage",
        display: 1,
        subMenu: [
          {
            icon: "PieChartIcon",
            pageName: "manage-statistics",
            title: "Statistics",
            display: 6,
          },
          {
            icon: "UsersIcon",
            pageName: "manage-churches",
            title: "Churches",
            display: 1,
          },
          {
            icon: "VideoIcon",
            pageName: "manage-videos",
            title: "Videos",
            display: 2,
          },
          {
            icon: "PlayCircleIcon",
            pageName: "manage-streams",
            title: "Streams",
            display: 3,
          },
          {
            icon: "ListIcon",
            pageName: "manage-categories",
            title: "Categories",
            display: 4,
          },
          {
            icon: "ApertureIcon",
            pageName: "processing",
            title: "Processing",
            display: 5,
          },
          {
            icon: "SettingsIcon",
            pageName: "settings",
            title: "Settings",
            display: 7,
          },
        ],
      },
      {
        icon: "PieChartIcon",
        pageName: "statistics",
        title: "Statistics",
        display: 2,
      },
      // {
      //   icon: "PlayCircleIcon",
      //   pageName: "streams",
      //   title: "Live Stream",
      //   display: 4,
      // },
      {
        icon: "ListIcon",
        pageName: "categories",
        title: "Uploads",
        display: 5,
      },
      {
        icon: "UserIcon",
        pageName: "profile",
        title: "Profile",
        display: 3,
      },
      {
        icon: "PlayCircleIcon",
        pageName: "streams",
        title: "Streams",
        display: 8,
        subMenu: [
          {
            icon: "plusIcon",
            pageName: "streams",
            title: "Add New Stream",
            openType: "popup",
            display: 9,
            sortid: 6,
          },
          {
            icon: "PlayCircleIcon",
            pageName: "streams",
            title: "All Streams",
            openType: "",
            display: 4,
            sortid: 7,
          },
        ],
      },
    ],
  };
};

// getters
const getters = {
  menu: (state) => state.menu,
};

// actions
const actions = {};

// mutations
const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
</script>
