import api from "../api";
import axiosutil from "../api/AxiosConfig";
import EventBus from "@/config/eventBus-services";

// state
const state = () => {
  return {
    darkMode: false,
    userData: "",
    pageTitle: "",
    streamPublishStatus: [],
    videoUploadProgress: [],
    streamTargetStatus: [],
  };
};

// getters
const getters = {
  darkMode: (state) => state.darkMode,
  userData: (state) => state.userData,
  streamPublishStatus: (state) => state.streamPublishStatus,
  videoUploadProgress: (state) => state.videoUploadProgress,
  pageTitle: (state) => state.pageTitle,
  streamTargetStatus: (state) => state.streamTargetStatus,
};

// mutations
const mutations = {
  CLEAR_STATE(state) {
    state.streamPublishStatus = [];
    state.videoUploadProgress = [];
    state.streamTargetStatus = [];
  },
  PAGE_TITLE(state, response) {
    state.pageTitle = response;
  },
  SET_DARK_MODE(state, darkMode) {
    state.darkMode = darkMode;
  },
  USER_DATA(state, response) {
    state.userData = response;
  },
  /**
   * @param {*} state
   * @param {IoStreamPublish | IoStreamStatus} response
   * @param {"streamPublish" | "streamStatus"} ioEvent
   */
  RECORDS_PROCESSING_STATUS(state, response, ioEvent) {
    let i = state.streamPublishStatus.findIndex(
      (x) => x.streamId == response.streamId
    );
    if (i != -1) {
      state.streamPublishStatus[i] = response;
    } else {
      state.streamPublishStatus.push(response);
    }
    EventBus.emit("RECORDS_PROCESSING_STATUS", response);
  },
  RECORDS_TARGET_STATUS(state, response) {
    let i = state.streamTargetStatus.findIndex(
      (x) => x.streamId == response.streamId
    );
    if (i != -1) {
      state.streamTargetStatus[i] = response;
    } else {
      state.streamTargetStatus.push(response);
    }
  },
  VIDEO_UPLOAD_PROCESS(state, response) {
    let index = state.videoUploadProgress.findIndex((a) => a.id == response.id);
    if (index != -1) {
      state.videoUploadProgress[index].loaded = response.loaded;
      state.videoUploadProgress[index].percent = response.percent;
    }
  },
  VIDEO_UPLOAD_PROCESS_ID(state, response) {
    let data = {
      id: response.id,
      name: response.name,
    };
    state.videoUploadProgress.push(data);
  },
  VIDEO_UPLOAD_PROCESS_REMOVE(state, response) {
    let index = state.videoUploadProgress.findIndex((a) => a.id == response);
    if (index != -1) {
      state.videoUploadProgress.splice(index, 1);
    }
  },
};

// actions
const actions = {
  setDarkMode(context, darkMode) {
    context.commit("SET_DARK_MODE", darkMode);
  },
  userLogin(context, payload) {
    return api.userLogin(payload).then((response: any) => {
      if (response && response.accessToken) {
        axiosutil.setSession(response);
        return response;
      } else {
        return response;
      }
    });
  },
  userSignup(context, payload) {
    return api.userSignup(payload).then((response) => {
      return response;
    });
  },
  userLogout(context) {
    return api.userLogout().then((response) => {
      return response;
    });
  },
  userForgotPassword(context, payload) {
    return api.userForgotPassword(payload).then(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
      }
    );
  },
  resetPassword(context, payload) {
    return api.resetPassword(payload).then(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
      }
    );
  },
  activation: function (context, payload) {
    return api.activation(payload).then((response: any) => {
      if (response && response.accessToken) {
        axiosutil.setSession(response);
        return response;
      } else {
        return response;
      }
    });
  },
  getProfile(context) {
    return api.getProfile().then((response: any) => {
      if (response && response.user) {
        context.commit("USER_DATA", response.user);
      }
      return response;
    });
  },
  updateProfile(context, payload) {
    return api.updateProfile(payload).then((response: any) => {
      context.commit("USER_DATA", response.user);
      return response;
    });
  },
  changePassword(context, payload) {
    return api.changePassword(payload).then((response) => {
      return response;
    });
  },
  upload_image(context, payload) {
    return api.upload_image(payload).then((response: any) => {
      context.commit("USER_DATA", response.stream);
      return response;
    });
  },
  maxUploadImageSize(context) {
    return api.maxUploadImageSize().then((response) => {
      return response;
    });
  },
  getCategoryList(context, payload) {
    return api.getCategoryList(payload).then((response) => {
      return response;
    });
  },
  getCategoryAll(context) {
    return api.getCategoryAll().then((response) => {
      return response;
    });
  },
  getCategory(context, categoryId) {
    return api.getCategory(categoryId).then((response) => {
      return response;
    });
  },
  deleteCategory(context, categoryId) {
    return api.deleteCategory(categoryId).then((response) => {
      return response;
    });
  },
  deleteCategoryImage(context, categoryId) {
    return api.deleteCategoryImage(categoryId).then((response) => {
      return response;
    });
  },
  addNewCategory(context, payload) {
    return api.addNewCategory(payload).then((response) => {
      return response;
    });
  },
  updateCategory(context, { payload, id }) {
    return api.updateCategory(payload, id).then((response) => {
      return response;
    });
  },
  category_upload_image(context, { payload, id }) {
    return api.category_upload_image(payload, id).then((response) => {
      return response;
    });
  },
  getCategoryRecords(context, payload) {
    return api.getCategoryRecords(payload).then((response) => {
      return response;
    });
  },
  getRecords(context, id) {
    return api.getRecords(id).then((response) => {
      return response;
    });
  },
  image_from_screenshot(context, { payload, id }) {
    return api.image_from_screenshot(payload, id).then((response) => {
      return response;
    });
  },
  // addNewRecords(context, payload) {
  //   return api.addNewRecords(payload).then((response) => {
  //     if (response && response.record) {
  //       context.commit("VIDEO_UPLOAD_PROCESS", response.record);
  //     }
  //     return response;
  //   });
  // },
  deleteRecords(context, id) {
    return api.deleteRecords(id).then((response) => {
      return response;
    });
  },
  updateRecords(context, { payload, id }) {
    return api.updateRecords(payload, id).then((response) => {
      return response;
    });
  },
  records_upload_image(context, { payload, id }) {
    return api.records_upload_image(payload, id).then((response) => {
      return response;
    });
  },
  deleteRecordsImage(context, categoryId) {
    return api.deleteRecordsImage(categoryId).then((response) => {
      return response;
    });
  },
  getStream(context, id) {
    return api.getStream(id).then((response) => {
      return response;
    });
  },
  getStreamList(context, payload) {
    return api.getStreamList(payload).then((response) => {
      return response;
    });
  },
  addNewStream(context, payload) {
    return api.addNewStream(payload).then((response) => {
      return response;
    });
  },
  stream_upload_image(context, { payload, id }) {
    return api.stream_upload_image(payload, id).then((response) => {
      return response;
    });
  },
  deleteStream(context, id) {
    return api.deleteStream(id).then((response) => {
      return response;
    });
  },
  updateStream(context, { payload, id }) {
    return api.updateStream(payload, id).then((response) => {
      return response;
    });
  },
  deleteStreamImage(context, id) {
    return api.deleteStreamImage(id).then((response) => {
      return response;
    });
  },
  getStreamRecords(context, payload) {
    return api.getStreamRecords(payload).then((response) => {
      return response;
    });
  },
  changeRecordsOrder(context, { payload, id }) {
    return api.changeRecordsOrder(payload, id).then((response) => {
      return response;
    });
  },
  getStreamTargetsList(context, payload) {
    return api.getStreamTargetsList(payload).then((response) => {
      return response;
    });
  },
  deleteStreamTargets(context, id) {
    return api.deleteStreamTargets(id).then((response) => {
      return response;
    });
  },
  addNewStreamTargets(context, payload) {
    return api.addNewStreamTargets(payload).then((response) => {
      return response;
    });
  },
  addNewStreamTargetsFb(context, payload) {
    return api.addNewStreamTargetsFb(payload).then((response) => {
      return response;
    });
  },
  updateStreamTargets(context, { payload, id }) {
    return api.updateStreamTargets(payload, id).then((response) => {
      return response;
    });
  },
  updateStreamTargetsFb(context, { payload, id }) {
    return api.updateStreamTargetsFb(payload, id).then((response) => {
      return response;
    });
  },
  getSecondRecordStatus(context, id) {
    return api.getSecondRecordStatus(id).then((response) => {
      return response;
    });
  },
  second_recording_start(context, id) {
    return api.second_recording_start(id).then((response) => {
      return response;
    });
  },
  second_recording_stop(context, id) {
    return api.second_recording_stop(id).then((response) => {
      return response;
    });
  },
  set_second_recording_title(context, { payload, id }) {
    return api.set_second_recording_title(payload, id).then((response) => {
      return response;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
