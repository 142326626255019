import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import { createI18n } from "vue-i18n";

// SASS Theme
import "./assets/sass/app.scss";
import mitt from "mitt";
const emitter = mitt();

const i18n = createI18n({
  locale: "en",
  messages: {
    en: {
      "users.loginSuccess": "Login Successfully.",
      "users.loginOut": "You Got Out!.",
      "users.password_wrong": "Current password is wrong.",
      "users.password_wrong_or_not_found":
        "Email or password wrong or not found.",
      "jwt.bad_or_invalid_token": "Invalid token. Please try again.",
      "upload.invalid_mime": "Invalid. Please try again.",
      "profile.update_profile": "Profile updated successfully.",
      "profile.upload_image": "Image uploaded successfully.",
      "profile.password_reset": "Password reset successfully.",
      "users.valid_email": "Please enter valid email.",
      "image.size_8MB": "Please select less then 8MB image only.",
      "image.type_jpg_png": "Please select JPG/PNG image only.",
      "categories.not_found": "Please try again..",
      "categories.delete": "Category deleted.",
      "categories.deleteImage": "Category image deleted.",
      "categories.add": "Category add successfully.",
      "categories.image": "Category image updated.",
      "categories.update": "Category update successfully.",
      "categories.cannot_delete_category_linked_with_stream":
        "You can't delete the category associated with streams",
      "record.not_found": "Please try again..",
      "record.delete": "Videos deleted.",
      "record.deleteImage": "Videos image deleted.",
      "record.add": "Videos add successfully.",
      "record.update": "Videos update successfully.",
      "record.changeCategory": "Category changed successfully.",
      "stream.add": "Stream add successfully.",
      "stream.set_second_recording_title": "Second record add successfully.",
      "stream.delete": "Stream deleted.",
      "stream.not_found": "Please try again..",
      "stream.deleteImage": "Stream image deleted.",
      "stream.image": "Stream image updated.",
      "stream.increase_limits": "Contact support to increase limits.",
      "stream.update": "Stream update successfully.",
      "streamTargets.add": "StreamTargets add successfully.",
      "streamTargets.delete": "StreamTargets deleted.",
      "streamTargets.not_found": "Please try again..",
      "streamTargets.update": "StreamTargets update successfully.",
      "users.add": "Users add successfully.",
      "users.delete": "Users deleted.",
      "users.not_found": "Please try again..",
      "users.update": "Users update successfully.",
      "users.email_address_is_used": "Email address already used.",
      "settings.update": "Setting saved.",
      "uploadProc.verySmall": "Minimum video size require 256Kb.",
      "categories.changeRecordsOrder": "Category records are rearanged.",
    },
  },
});

const app = createApp(App).use(store).use(router).use(i18n);

app.config.globalProperties.emitter = emitter;
globalComponents(app);
utils(app);

app.mount("#app");
