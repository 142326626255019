import Toastify from "toastify-js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

declare const cash: any;

const helpers = {
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  formatDate(value) {
    return dayjs(value).format("MMMM D, YYYY hh:mm A");
  },
  date(value) {
    return dayjs(value).format("YYYY-MM-DDTHH:mm");
  },
  dateMonth(value) {
    return dayjs(value).format("MMM D, YYYY");
  },
  formatDateC(value) {
    return dayjs(value).format("YYYY-MM-DD");
  },
  dateHour(value) {
    return dayjs(value).format("YYYY-MM-DD HH:mm");
  },
  //2022-03-08T09:48:29.211Z to YYYY-MM-DD HH:mm:ss
  dateHHMMSS(value) {
    return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
  },
  dateMonthDDMM(value) {
    return dayjs(value)
      .utc(true)
      .format("YYYY-MM");
  },
  foramtDateTime(value) {
    return dayjs(value).format("YYYY-MM-DDTHH:mm:ssZ");
  },
  foramtDatetimeOfDayEnd(value) {
    return dayjs(value).format("YYYY-MM-DDT23:59:59Z");
  },
  // second to hh:mm:ss
  displayDuration(timestamp) {
    let hours = Math.floor(timestamp / 60 / 60);
    let minutes = Math.floor(timestamp / 60) - hours * 60;
    let seconds = timestamp % 60;
    return (
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  },
  //second to hours
  secondsToH(second) {
    var hours = Math.floor(second / 60 / 60);
    return hours.toString().padStart(2, "0");
  },
  toastSuccess() {
    Toastify({
      node: cash("#success-notification-save")
        .clone()
        .removeClass("hidden")[0],
      duration: 2000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "right",
      stopOnFocus: true,
    }).showToast();
  },
  getUuid() {
    return (
      Math.floor(Math.random() * 1e6 + 1e6).toString(36) +
      "-" +
      Date.now().toString(36)
    );
  },
  authRole() {
    let authRole = "";
    authRole = localStorage[btoa(btoa("session"))];
    authRole = authRole ? JSON.parse(atob(atob(authRole))) : "";
    authRole = authRole ? (authRole as any).user.role : "";
    return authRole;
  },
  useCategories() {
    let useCategories = "";
    useCategories = localStorage[btoa(btoa("session"))];
    useCategories = useCategories ? JSON.parse(atob(atob(useCategories))) : "";
    useCategories = useCategories ? (useCategories as any).user.useCategories : "";
    return useCategories;
  },
  getLocalUtcOffset() {
    return -new Date().getTimezoneOffset();
  },
};

const install = (app) => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
