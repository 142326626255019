import api from "../api";
import axiosutil from "../api/AxiosConfig";

// state
const state = () => {
  return {};
};

// getters
const getters = {};

// mutations
const mutations = {};

// actions
const actions = {
  recordsProcessingInfo(context) {
    return api.recordsProcessingInfo().then((response) => {
      return response;
    });
  },
  users(context, payload) {
    return api.users(payload).then((response) => {
      return response;
    });
  },
  getUsersAdmin(context, id) {
    return api.getUsersAdmin(id).then((response) => {
      return response;
    });
  },
  addNewUsers(context, payload) {
    return api.addNewUsers(payload).then((response) => {
      return response;
    });
  },
  deleteUsers(context, id) {
    return api.deleteUsers(id).then((response) => {
      return response;
    });
  },
  updateUsers(context, { payload, id }) {
    return api.updateUsers(payload, id).then((response) => {
      return response;
    });
  },
  loginAs(context, id) {
    return api.loginAs(id).then((response) => {
      axiosutil.clearSession();
      axiosutil.setSession(response);
      return response;
    });
  },
  getCategoryListAdmin(context, payload) {
    return api.getCategoryListAdmin(payload).then((response) => {
      return response;
    });
  },
  // getCategoryAdmin(context, id) {
  //   return api.getCategoryAdmin(id).then((response) => {
  //     return response;
  //   });
  // },
  getRecordsListAdmin(context, payload) {
    return api.getRecordsListAdmin(payload).then((response) => {
      return response;
    });
  },
  // getRecordsAdmin(context, id) {
  //   return api.getRecordsAdmin(id).then((response) => {
  //     return response;
  //   });
  // },
  deleteRecordsAdmin(context, id) {
    return api.deleteRecordsAdmin(id).then((response) => {
      return response;
    });
  },
  getStreamListAdmin(context, payload) {
    return api.getStreamListAdmin(payload).then((response) => {
      return response;
    });
  },
  // getStreamAdmin(context, id) {
  //   return api.getStreamAdmin(id).then((response) => {
  //     return response;
  //   });
  // },
  settings(context) {
    return api.settings().then((response) => {
      return response;
    });
  },
  record_upload_target(context, payload) {
    return api.record_upload_target(payload).then((response) => {
      return response;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
