import axios from "axios";
import { show, hide } from "../assets/js/common";
import router from "@/router";

export default {
  async post(uri: string, requestData?: any) {
    try {
      show();
      const res = await axios.post(uri, requestData);
      hide();
      return res.data;
    } catch (error: any) {
      hide();
      if (error.response.data) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
  async postWithoutLoader(uri: string, requestData?: any) {
    try {
      const res = await axios.post(uri, requestData);
      return res.data;
    } catch (error: any) {
      if (error.response.data) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
  async get(uri: string, requestData?: any) {
    try {
      show();
      const res = await axios.get(uri, {
        params: requestData,
      });
      hide();
      return res.data;
    } catch (error: any) {
      hide();
      if (
        error.response.data.statusCode == 403 ||
        error.response.status == 502
      ) {
        this.clearSession();
        router.push({ name: "login" });
      }
      hide();
      return error.response.data;
    }
  },
  async getWithoutLoader(uri: string, requestData?: any) {
    try {
      const res = await axios.get(uri, {
        params: requestData,
      });
      return res.data;
    } catch (error: any) {
      if (
        error.response.data.statusCode == 403 ||
        error.response.status == 502
      ) {
        this.clearSession();
        router.push({ name: "login" });
      }
      return error.response.data;
    }
  },
  async delete(uri: string, requestData?: any) {
    try {
      show();
      const res = await axios.delete(uri, requestData);
      hide();
      return res.data;
    } catch (error: any) {
      hide();
      if (error.response.data) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
  async put(uri: string, requestData?: any) {
    try {
      show();
      const res = await axios.put(uri, requestData);
      hide();
      return res.data;
    } catch (error: any) {
      hide();
      if (error.response.data) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
  clearSession() {
    localStorage.removeItem(btoa(btoa("accessToken")));
    localStorage.removeItem(btoa(btoa("AuthKey")));
    localStorage.removeItem(btoa(btoa("id")));
    localStorage.removeItem(btoa(btoa("session")));
  },
  setSession(response) {
    if (response) {
      localStorage.setItem(
        btoa(btoa("accessToken")),
        btoa(btoa(response.accessToken))
      );
      localStorage.setItem(btoa(btoa("id")), btoa(btoa(response.user.id)));
      localStorage.setItem(
        btoa(btoa("session")),
        btoa(btoa(JSON.stringify(response)))
      );
      localStorage.setItem(
        btoa(btoa("AuthKey")),
        btoa(btoa("Bearer " + response.accessToken))
      );
    }
  },
};

axios.interceptors.request.use((config) => {
  if (localStorage[btoa(btoa("AuthKey"))]) {
    let token = "";
    token = localStorage[btoa(btoa("AuthKey"))];
    config.headers["Authorization"] = atob(atob(token));
  }
  config.headers.crossDomain = true;
  config.headers.withCredentials = true;
  config.headers["TokenType"] = "web";
  config.url = "/" + config.url;
  return config;
});
