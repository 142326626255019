import { createRouter, createWebHistory } from "vue-router";
import { helper as $h } from "@/utils/helper";

function isLoggedIn(to, from, next) {
  const session = localStorage.getItem(btoa(btoa("AuthKey")));
  if (session !== undefined && session !== null) {
    let sessionData = localStorage.getItem(btoa(btoa("session"))) as any;
    sessionData = atob(atob(sessionData)).toLowerCase();
    sessionData = JSON.parse(sessionData);
    if (sessionData && sessionData.user.id) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next({ name: "login" });
  }
}
function loginRedirect(to, from, next) {
  const session = localStorage.getItem(btoa(btoa("AuthKey")));
  if (session) {
    alert("You are already logged in..");
    next({ path: "/" });
  } else {
    next();
  }
}
function useCategoriesModule(to, from, next) {
  if (
    $h.useCategories() &&
    ($h.authRole() == "ADMIN" || $h.authRole() == "USER")
  ) {
    next();
  } else {
    alert("Contact support to allow Uploads.");
    next(from.path);
  }
}
function authAdminUser(to, from, next) {
  if ($h.authRole() != "GUEST") {
    next();
  } else {
    alert("Contact to support team.");
    next({ name: "profile" });
  }
}
function authAdmin(to, from, next) {
  if (localStorage[btoa(btoa("tmpAccessToken"))]) {
    next({ path: "/" });
  } else if ($h.authRole() != "GUEST" && $h.authRole() != "USER") {
    next();
  } else {
    alert("Contact to support team.");
    next(from.path);
  }
}
const routes = [
  {
    path: "/login",
    name: "login",
    meta: { title: "Login" },
    component: () => import("../views/login/Main.vue"),
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
    },
  },
  {
    path: "/",
    component: () => import("../layouts/side-menu/Main.vue"),
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
    children: [
      {
        path: "/",
        name: "statistics",
        meta: { title: "Statistics" },
        component: () => import("../views/statistics/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "/admin/users",
        name: "manage-churches",
        meta: { title: "Manage Churches" },
        component: () => import("../views/admin/users/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/statistics",
        name: "manage-statistics",
        meta: { title: "Manage Statistics" },
        component: () => import("../views/admin/statistics/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/users/create",
        name: "create-church",
        meta: { title: "Create Churche" },
        component: () =>
          import("../views/admin/users/components/create-update/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/users/:id",
        name: "update-church",
        meta: { title: "Update Churche" },
        component: () =>
          import("../views/admin/users/components/create-update/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/videos",
        name: "manage-videos",
        meta: { title: "Manage Videos" },
        component: () => import("../views/admin/videos/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/streams",
        name: "manage-streams",
        meta: { title: "Manage Streams" },
        component: () => import("../views/admin/streams/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/categories",
        name: "manage-categories",
        meta: { title: "Manage Categories" },
        component: () => import("../views/admin/categories/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/processing",
        name: "processing",
        meta: { title: "Manage Processing" },
        component: () => import("../views/admin/processing/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "/admin/settings",
        name: "settings",
        meta: { title: "System settings" },
        component: () => import("../views/admin/settings/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdmin(to, from, next);
        },
      },
      {
        path: "profile",
        name: "profile",
        meta: { title: "Profile" },
        component: () => import("../views/profile/Main.vue"),
      },
      {
        path: "categories",
        name: "categories",
        meta: { title: "Uploads" },
        component: () => import("../views/categories/Main.vue"),
        beforeEnter: (to, from, next) => {
          useCategoriesModule(to, from, next);
        },
      },
      {
        path: "categories/:id",
        name: "specific-category",
        component: () =>
          import("../views/categories/component/records/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          useCategoriesModule(to, from, next);
        },
      },
      {
        path: "categories/:id/preview",
        name: "preview-category",
        meta: { title: "Preview Category" },
        component: () =>
          import("../views/categories/component/preview/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          useCategoriesModule(to, from, next);
        },
      },
      {
        path: "categories/records/:id/preview",
        name: "preview categories videos",
        meta: { title: "Preview Videos" },
        component: () =>
          import("../views/categories/component/preview/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          useCategoriesModule(to, from, next);
        },
      },
      {
        path: "streams",
        name: "streams",
        meta: { title: "Live Stream" },
        component: () => import("../views/streams/Main.vue"),
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "streams/:id",
        name: "stream-page",
        component: () => import("../views/streams/component/records/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "streams/:id/chat",
        name: "broadcast-chat",
        component: () => import("../views/streams/component/chat/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "streams/:id/preview",
        name: "preview-stream",
        meta: { title: "Preview Stream" },
        component: () => import("../views/streams/component/preview/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "streams/:id/targets",
        name: "stream-targets",
        meta: { title: "Stream Targets" },
        component: () =>
          import("../views/streams/component/records/stream-targets/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "stream/records/:id/preview",
        name: "preview stream videos",
        meta: { title: "Preview Videos" },
        component: () =>
          import("../views/categories/component/preview/Main.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          authAdminUser(to, from, next);
        },
      },
      {
        path: "empty",
        name: "empty",
        meta: { title: "Empty page" },
        component: () => import("../views/EmptyPage.vue"),
      },
    ],
  },
  {
    path: "/streams/:id/broadcast",
    name: "broadcast",
    meta: { title: "Broadcast" },
    component: () =>
      import("../views/streams/component/records/broadcast/Main.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      authAdminUser(to, from, next);
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { title: "Forgot Password" },
    component: () => import("../views/forgot-password/Main.vue"),
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
    },
  },
  {
    path: "/activation/:code?",
    name: "activation",
    meta: { title: "Activation" },
    component: () => import("../views/activation/Main.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: { title: "Privacy Policy" },
    component: () => import("../views/privacy-policy/Main.vue"),
  },
  {
    path: "/privacy-policy-7-aug-2022",
    name: "privacy-policy-7-aug-2022",
    meta: { title: "Privacy Policy" },
    component: () => import("../views/privacy-policy/Main.vue"),
  },
  {
    path: "/recovery/:code?",
    name: "reset-password",
    meta: { title: "Reset Password" },
    component: () => import("../views/reset-password/Main.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { title: "Sign Up" },
    component: () => import("../views/register/Main.vue"),
    beforeEnter: (to, from, next) => {
      loginRedirect(to, from, next);
    },
  },
  {
    path: "/error-page",
    name: "error-page",
    meta: { title: "Error Page" },
    component: () => import("../views/error-page/Main.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/error-page/Main.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title =
    to.meta && to.meta.title
      ? to.meta.title + " | Jesus Stream"
      : "Jesus Stream";
  next();
});

export default router;
