import { createStore } from "vuex";
import main from "./main";
import admin from "./admin";
import sideMenu from "./side-menu.vue";

const store = createStore({
  modules: {
    // @ts-ignore
    sideMenu,
    // @ts-ignore
    main,
    admin,
  },
});

export function useStore() {
  return store;
}

export default store;
