import axiosutil from "./AxiosConfig";

const API = {
  userLogin: "api/users/login",
  userSignup: "api/users/signup",
  userForgotPassword: "api/users/forgot",
  resetPassword: "api/users/recovery",
  userLogout: "api/user/logout",
  activation: "api/users/activation",
  getProfile: "api/user/profile",
  updateProfile: "api/user/profile",
  changePassword: "api/user/profile/password",
  upload_image: "api/user/upload_image",
  maxUploadImageSize: "api/configs",
  //categories
  getCategoryList: "api/user/categories",
  changeRecordsOrder: "api/user/categories",
  getCategoryAll: "api/user/categories/all",
  getCategory: "api/user/categories",
  deleteCategory: "api/user/categories",
  addNewCategory: "api/user/categories",
  category_upload_image: "api/user/categories",
  deleteCategoryImage: "api/user/categories",
  updateCategory: "api/user/categories",
  getCategoryRecords: "api/user/categories",
  //records
  getRecords: "api/user/records",
  image_from_screenshot: "api/user/records",
  // addNewRecords: "api/user/records",
  deleteRecords: "api/user/records",
  updateRecords: "api/user/records",
  records_upload_image: "api/user/records",
  deleteRecordsImage: "api/user/records",
  //stream
  getStreamList: "api/user/streams",
  addNewStream: "api/user/streams",
  stream_upload_image: "api/user/streams",
  deleteStream: "api/user/streams",
  updateStream: "api/user/streams",
  deleteStreamImage: "api/user/streams",
  getStream: "api/user/streams",
  getStreamRecords: "api/user/streams",
  getSecondRecordStatus: "api/user/streams",
  second_recording_start: "api/user/streams",
  second_recording_stop: "api/user/streams",
  set_second_recording_title: "api/user/streams",
  //stream_targets
  getStreamTargetsList: "api/user/stream_targets",
  deleteStreamTargets: "api/user/stream_targets",
  addNewStreamTargets: "api/user/stream_targets",
  addNewStreamTargetsFb: "api/user/stream_targets/fb",
  updateStreamTargets: "api/user/stream_targets",
  updateStreamTargetsFb: "api/user/stream_targets",
  //fb
  getUserFb: "api/user/fb",
  updateUserFb: "api/user/fb",
  // Admin API
  recordsProcessingInfo: "api/admin/processing",
  users: "api/admin/users",
  getUsersAdmin: "api/admin/users",
  loginAs: "api/admin/users",
  addNewUsers: "api/admin/users",
  deleteUsers: "api/admin/users",
  updateUsers: "api/admin/users",
  getCategoryListAdmin: "api/admin/categories",
  // getCategoryAdmin: "api/admin/categories",
  getRecordsListAdmin: "api/admin/records",
  // getRecordsAdmin: "api/admin/records",
  deleteRecordsAdmin: "api/admin/records",
  getStreamListAdmin: "api/admin/streams",
  // getStreamAdmin: "api/admin/streams",

  settings: "api/admin/settings/root",
  record_upload_target: "api/admin/settings/root/record_upload_target",
};

export default {
  userLogin(payload) {
    return axiosutil.post(API.userLogin, payload);
  },
  userSignup(payload) {
    return axiosutil.post(API.userSignup, payload);
  },
  userLogout() {
    return axiosutil.post(API.userLogout);
  },
  userForgotPassword(payload) {
    return axiosutil.post(API.userForgotPassword, payload);
  },
  resetPassword(payload) {
    return axiosutil.post(API.resetPassword, payload);
  },
  activation(payload) {
    return axiosutil.post(API.activation, payload);
  },
  getProfile() {
    return axiosutil.get(API.getProfile);
  },
  updateProfile(payload) {
    return axiosutil.put(API.updateProfile, payload);
  },
  changePassword(payload) {
    return axiosutil.put(API.changePassword, payload);
  },
  upload_image(payload) {
    return axiosutil.post(API.upload_image, payload);
  },
  maxUploadImageSize() {
    return axiosutil.get(API.maxUploadImageSize);
  },
  //categories
  getCategoryList(payload) {
    return axiosutil.get(API.getCategoryList, payload);
  },
  getCategory(id) {
    return axiosutil.get(API.getCategory + "/" + id);
  },
  changeRecordsOrder(payload, id) {
    return axiosutil.post(
      API.changeRecordsOrder + "/" + id + "/change_records_order",
      payload
    );
  },
  getCategoryAll() {
    return axiosutil.get(API.getCategoryAll);
  },
  deleteCategory(categoryId) {
    return axiosutil.delete(API.deleteCategory + "/" + categoryId);
  },
  deleteCategoryImage(categoryId) {
    return axiosutil.delete(
      API.deleteCategoryImage + "/" + categoryId + "/image"
    );
  },
  addNewCategory(payload) {
    return axiosutil.post(API.addNewCategory, payload);
  },
  category_upload_image(payload, id) {
    return axiosutil.post(
      API.category_upload_image + "/" + id + "/upload_image",
      payload
    );
  },
  updateCategory(payload, id) {
    return axiosutil.post(API.updateCategory + "/" + id, payload);
  },
  //records
  getCategoryRecords(payload) {
    return axiosutil.get(
      API.getCategoryRecords + "/" + payload.id + "/records"
    );
  },
  getRecords(id) {
    return axiosutil.get(API.getRecords + "/" + id);
  },
  // addNewRecords(payload) {
  //   return axiosutil.postWithoutLoader(API.addNewRecords, payload);
  // },
  deleteRecords(id) {
    return axiosutil.delete(API.deleteRecords + "/" + id);
  },
  updateRecords(payload, id) {
    return axiosutil.post(API.updateRecords + "/" + id, payload);
  },
  image_from_screenshot(payload, id) {
    return axiosutil.post(
      API.image_from_screenshot + "/" + id + "/image_from_screenshot",
      payload
    );
  },
  records_upload_image(payload, id) {
    return axiosutil.post(
      API.records_upload_image + "/" + id + "/upload_image",
      payload
    );
  },
  deleteRecordsImage(categoryId) {
    return axiosutil.delete(
      API.deleteRecordsImage + "/" + categoryId + "/image"
    );
  },
  //Stream
  getStream(id) {
    return axiosutil.get(API.getStream + "/" + id);
  },
  getStreamList(payload) {
    return axiosutil.get(API.getStreamList, payload);
  },
  addNewStream(payload) {
    return axiosutil.post(API.addNewStream, payload);
  },
  stream_upload_image(payload, id) {
    return axiosutil.post(
      API.stream_upload_image + "/" + id + "/upload_image",
      payload
    );
  },
  deleteStream(id) {
    return axiosutil.delete(API.deleteStream + "/" + id);
  },
  updateStream(payload, id) {
    return axiosutil.post(API.updateStream + "/" + id, payload);
  },
  deleteStreamImage(id) {
    return axiosutil.delete(API.deleteStreamImage + "/" + id + "/image");
  },
  getSecondRecordStatus(id) {
    return axiosutil.get(
      API.getSecondRecordStatus + "/" + id + "/is_second_recording_started"
    );
  },
  second_recording_start(id) {
    return axiosutil.post(
      API.second_recording_start + "/" + id + "/second_recording_start",
        {id: id}
    );
  },
  second_recording_stop(id) {
    return axiosutil.post(
      API.second_recording_stop + "/" + id + "/second_recording_stop",
        {id: id}
    );
  },
  set_second_recording_title(payload, id) {
    return axiosutil.post(
      API.set_second_recording_title + "/" + id + "/set_second_recording_title",
      payload
    );
  },
  getStreamRecords(payload) {
    return axiosutil.get(
      API.getStreamRecords + "/" + payload.id + "/records",
      payload
    );
  },
  //stream_targets
  getStreamTargetsList(payload) {
    return axiosutil.get(API.getStreamTargetsList, payload);
  },
  deleteStreamTargets(id) {
    return axiosutil.delete(API.deleteStreamTargets + "/" + id);
  },
  addNewStreamTargets(payload) {
    return axiosutil.post(API.addNewStreamTargets, payload);
  },
  addNewStreamTargetsFb(payload) {
    return axiosutil.post(API.addNewStreamTargetsFb, payload);
  },
  updateStreamTargets(payload, id) {
    return axiosutil.post(API.updateStreamTargets + "/" + id, payload);
  },
  updateStreamTargetsFb(payload, id) {
    return axiosutil.post(API.updateStreamTargets + "/" + id + "/fb", payload);
  },
  //fb
  getUserFb() {
    return axiosutil.get(API.getUserFb);
  },
  updateUserFb(payload) {
    return axiosutil.post(API.updateUserFb, payload);
  },
  //Admin API
  recordsProcessingInfo() {
    return axiosutil.getWithoutLoader(API.recordsProcessingInfo);
  },
  users(payload) {
    return axiosutil.get(API.users, payload);
  },
  getUsersAdmin(id) {
    return axiosutil.get(API.getUsersAdmin + "/" + id);
  },
  addNewUsers(payload) {
    return axiosutil.post(API.addNewUsers, payload);
  },
  deleteUsers(id) {
    return axiosutil.delete(API.deleteUsers + "/" + id);
  },
  updateUsers(payload, id) {
    return axiosutil.put(API.updateUsers + "/" + id, payload);
  },
  loginAs(id) {
    return axiosutil.post(API.loginAs + "/" + id + "/" + "login");
  },
  getCategoryListAdmin(payload) {
    return axiosutil.get(API.getCategoryListAdmin, payload);
  },
  // getCategoryAdmin(id) {
  //   return axiosutil.get(API.getCategoryAdmin + "/" + id);
  // },
  getRecordsListAdmin(payload) {
    return axiosutil.get(API.getRecordsListAdmin, payload);
  },
  // getRecordsAdmin(id) {
  //   return axiosutil.get(API.getRecordsAdmin + "/" + id);
  // },
  deleteRecordsAdmin(id) {
    return axiosutil.delete(API.deleteRecordsAdmin + "/" + id);
  },
  getStreamListAdmin(payload) {
    return axiosutil.get(API.getStreamListAdmin, payload);
  },
  // getStreamAdmin(id) {
  //   return axiosutil.get(API.getStreamAdmin + "/" + id);
  // },
  //pvs
  settings() {
    return axiosutil.get(API.settings);
  },
  record_upload_target(payload) {
    return axiosutil.put(API.record_upload_target, payload);
  },
};
